<template>
  <!-- 联系客服 -->
  <div>
    <div class="main" v-if="dataList[0]">
      <div class="codeBox">
        <img :src="dataList[0].imgUrl" alt="">
        <div class="text" v-if="![null,'',undefined].includes(dataList[0].imgTip)">          
          {{ dataList[0].imgTip }}
        </div>
        <button class="submit" @click="submit(dataList[0].url)" v-if="dataList[0].url">
            {{ dataList[0].buttonText || '立即联系' }}          
        </button>
      </div>
      <div class="codeBoxPhone" v-for="(item, index) in mobileList" :key="index">
        <div class="codeBoxPhoneChild">
          <div>客服热线{{ index + 1 }}:{{ item }}</div>
          <button class="phoneSubmit" @click="callPhone(item)">
            拨打
          </button>
        </div>
      </div>
      <div class="serviceTime" v-if="dataList[0].remark">
        {{ dataList[0].remark }}
      </div>
    </div>
  </div>
</template>
  
<script>
import { onMounted, reactive, toRefs, getCurrentInstance } from "vue";
import { zyAdBannerList } from "_API_/api.services";
export default {
  setup() {
    const state = reactive({
      theme: "", //主题名称
      dataList: [{}],
      mobileList: [],
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store } = ctx;
    const methods = {
      callPhone(phoneNumber) {
        window.location.href = 'tel://' + phoneNumber
      },
      submit(url) {
        window.location.href = url
      },
      //获取数据
      getList(type) {
        zyAdBannerList(7).then(({ data: res }) => {
          console.log(res)
          if (res.code == 0) {
            if (res.data[0].hotline) {
              this.mobileList = res.data[0].hotline.split(/,|，|\s+/)
            }
            state.dataList = res.data
            return
          }
          tool.toast(
            {
              msg: res.msg,
              duration: 1500
            },
            () => { }
          );
        })
      },
    };
    onMounted(() => {
      //获取主题名称
      state.theme = store.state.common.theme;
      ctx.getList()
    });
    return {
      ...toRefs(state),
      ...methods,
      tool
    };
  }
};
</script>
  
<style lang="scss" scoped>
@import "@/style/theme/index";

.main {
  width: 100%;
  height: 100%;

  .codeBox {
    width: 85%;
    margin: auto;
    margin-top: 60px;
    background: #FAFBFF;
    border: solid 1px #cccccc6b;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding-bottom: 48px;
  }

  img {
    width: 60%;
    margin-top: 80px;

  }

  .text {
    margin-top: 30px;
  }

  .submit {
    width: 400px;
    height: 84px;
    color: #ffffff;
    @include background_color("background_color1");
    // box-shadow: 0px 8px 16px 0px #bce1ff;
    border-radius: 10px;
    border: none;
    margin: 50px 50px 0px 26px;

  }

  .submit[disabled] {
    @include background_color("background_color2");
  }

  .codeBoxPhone {
    width: 85%;
    margin: auto;
    margin-top: 30px;
    background: #FAFBFF;
    border: solid 1px #cccccc6b;
    border-radius: 20px;

    .codeBoxPhoneChild {
      padding: 40px 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .phoneSubmit {
      width: 140px;
      height: 64px;
      color: #ffffff;
      @include background_color("background_color1");
      // box-shadow: 0px 8px 16px 0px #bce1ff;
      border-radius: 10px;
      border: none;
    }
  }

  .serviceTime {
    width: 85%;
    margin: auto;
    color: #8f8e8e;
    font-size: 28px;
    line-height: 100px;
    text-align: center;
  }

}
</style>
  